<template>
  <div class="detail">
    <el-card>
      <div class="info">
        <div class="image">
          <el-image :src="detailInfo.coverUrl" />
        </div>
        <div class="description">
          <el-descriptions :column="1">
            <el-descriptions-item label="活动名称">{{ detailInfo.title }}</el-descriptions-item>
            <el-descriptions-item label="活动状态">{{ detailInfo.status === 0 ? '未发布' : '已发布' }}</el-descriptions-item>
            <el-descriptions-item label="主办方">{{ detailInfo.organizer }}</el-descriptions-item>
            <el-descriptions-item label="活动简介">{{ detailInfo.intro }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </el-card>

    <el-card class="vote-card">
      <template #header>
        <el-tabs v-model="activeName" @tab-click="onChangeType">
          <el-tab-pane label="访问记录" name="history">
            <el-table
              :data="historyList"
              border
              stripe
              style="width: 100%;"
            >
              <el-table-column prop="doctorName" label="姓名" />
              <el-table-column prop="title" label="职称" />
              <el-table-column prop="department" label="科室" />
              <el-table-column prop="hospitalName" label="医院" />
              <el-table-column prop="isCertify" label="是否认证">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.isCertify ? '是' : '否' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="dateTime" label="访问时间" />
              <el-table-column prop="accessFromType" label="渠道">
                <template slot-scope="scope">
                  <div>
                    {{ onFromType(scope.row.accessFromType) }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="投票数据" name="vote">
            <el-form label-suffix="：" inline>
              <el-form-item label="医生名称">
                <el-input v-model="voteForm.doctorName" clearable placeholder="请输入医生名称" />
              </el-form-item>
              <el-button @click="onGetVoteList">搜索</el-button>
            </el-form>
            <el-table
              :data="voteList"
              border
              stripe
              style="width: 100%;"
            >
              <el-table-column
                v-for="(val,key) in voteDataKeyMap"
                :key="key"
                :prop="val.prop"
                :label="key"
              />
              <!-- <el-table-column prop="brandingTitle" label="投票活动" />
              <el-table-column prop="doctorName" label="姓名" />
              <el-table-column prop="department" label="科室" />
              <el-table-column prop="title" label="职称" />
              <el-table-column prop="hospitalName" label="医院" />
              <el-table-column prop="dateTime" label="投票时间" />
              <el-table-column prop="material" label="选择作品" /> -->
            </el-table>
          </el-tab-pane>
        </el-tabs>
        <el-pagination
          :current-page="searchObj.pageNo"
          :page-size="searchObj.pageSize"
          background
          hide-on-single-page
          layout="total, prev, pager, next, jumper"
          :total="total"
          style="text-align: center; padding-top: 20px;"
          @current-change="handleCurrentChange"
        />
        <el-row class="actions" type="flex" justify="end">
          <el-button v-if="detailInfo.status === 0" @click="onJumpEdit">编辑</el-button>
          <div style="margin: 0 10px;">
            <el-popover v-model="visible" placement="top" width="225">
              <div id="qrCode" ref="qrCodeDiv" style="width: 100%;" />
              <div style="text-align: center; margin-top: 10px;">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="visible = false">确定</el-button>
              </div>
              <el-button slot="reference">预览</el-button>
            </el-popover>
          </div>
          <el-button v-if="detailInfo.status === 0" @click="onBrandingDel">删除</el-button>
          <el-button @click="onChangeStatus">{{ detailInfo.status === 0 ? '发布' : '取消发布' }}</el-button>
        </el-row>

        <el-link
          v-if="activeName === 'vote'"
          class="download"
          type="primary"
          @click="download"
        >
          下载投票数据
        </el-link>
      </template>
    </el-card>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {JsonToExcel} from '@/utils/util.js'

export default {
  name: 'ActivityDetail',
  data() {
    return {
      brandingId: '',
      activeName: 'history',
      detailInfo: {},
      historyList: [],
      voteList: [],
      voteForm: {
        doctorName: ''
      },
      total: 0,
      searchObj: {
        pageNo: 1,
        pageSize: 10
      },
      voteDataKeyMap: {
        '投票活动': {
          prop: 'brandingTitle'
        },
        '姓名': {
          prop: 'doctorName'
        },
        '科室': {
          prop: 'department'
        },
        '职称': {
          prop: 'title'
        },
        '医院': {
          prop: 'hospitalName'
        },
        '投票时间': {
          prop: 'dateTime'
        },
        '选择作品': {
          prop: 'material'
        }
      },
      visible: false
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.onGetQrCode()
      }
    }
  },
  mounted() {
    this.brandingId = this.$route.params.id
    this.onGetDetail()
    this.onChangeType()
  },
  methods: {
    onFromType(val) {
      let obj = {
        1: 'banner',
        2: '学术交流圈',
        3: '站外分享链接'
      }
      return obj[String(val)]
    },
    onGetDetail() {
      this.$axios.get(this.$API.getBrandingDetailView, { params: { brandingId: this.brandingId } }).then(res => {
        this.detailInfo = res
      })
    },
    onGetHistoryList() {
      this.$axios.get(this.$API.getBrandingAccessRecord, { params: { ...this.searchObj, brandingId: this.brandingId } }).then(res => {
        this.historyList = res.data
        this.total = res.totalCount
      })
    },
    onGetVoteList() {
      let params = Object.assign(
        {
          ...this.searchObj,
          brandingId: this.brandingId
        },
        this.voteForm
      )
      this.$axios.get(this.$API.getBrandingVoteRecord, { params }).then(res => {
        this.voteList = res.data
        this.total = res.totalCount
      })
    },
    onChangeType() {
      this.onGetList(1)
    },
    onGetList(pageNo) {
      this.searchObj.pageNo = pageNo
      if (this.activeName === 'history') {
        this.onGetHistoryList()
      } else {
        this.onGetVoteList()
      }
    },
    handleCurrentChange(pageNo) {
      this.onGetList(pageNo)
    },
    onChangeStatus() {
      let text = this.detailInfo.status === 0 ? '发布' : '取消发布'
      this.$confirm(`确定${text}该活动吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let data = {
            status: this.detailInfo.status === 0 ? 1 : 0,
            id: this.brandingId
          }
          this.$axios.post(this.$API.getBrandingStatus, null, { params: data }).then(() => {
            this.$message.success(`${text}成功`)
            this.onGetDetail()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    onBrandingDel() {
      this.$confirm('确定删除该活动吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$axios.post(this.$API.getBrandingDel, null, { params: { id: this.brandingId } }).then(() => {
            this.$message.success('删除成功')
            this.$router.go(-1)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    onGetQrCode() {
      this.$refs.qrCodeDiv.innerHTML = '' // 清除二维码
      new QRCode(this.$refs.qrCodeDiv, {
        text: `${this.detailInfo.brandingUrl}?isPreview=true`,
        width: 200,
        height: 200,
        colorDark: '#333333', // 二维码颜色
        colorLight: '#ffffff', // 二维码背景色
        correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
      })
    },
    onJumpEdit() {
      this.$router.push(`/activityManagement/edit/${this.brandingId}`)
    },
    // 导出数据
    async download() {
      try {
        this.downloadloading = true
        let header = []
        for (let key in this.voteDataKeyMap) {
          header.push(key)
        }
        const {data} = await this.$axios.get(this.$API.getBrandingVoteRecord, {params: {pageNo: 1, pageSize: 99999, brandingId: this.$route.params.id}})
        let json = data.map(item => {
          return header.map(headerItem => {
            return item[this.voteDataKeyMap[headerItem].prop]
          })
        })
        JsonToExcel(this.detailInfo.title, [header, ...json])
        this.downloadloading = false
      } catch (error) {
        this.downloadloading = false
        console.log('%c [ error ]-257', 'font-size:14px; background:pink; color:#bf2c9f;', error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-image {
  width: 300px;
  height: 160px;
  object-fit: cover;
}
.info {
  display: flex;
}
.description {
  flex: 1;
  margin-left: 20px;
  margin-top: 20px;
}
.actions {
  margin-top: 20px;
}
.vote-card {
  position: relative;
  .download {
    position: absolute;
    right: 30px;
    top: 30px;
  }
}
</style>
